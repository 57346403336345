

































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class BankTransferModal extends Vue {
  loading = true;
  iban = "";
  error = "";
  loadingSubmit = false;
  showIbanCopied = false;
  showNameCopied = false;
  mounted(): void {
    this.loadUserIBAN();
  }

  closeModal(): void {
    this.$store.dispatch("modal/hide");
  }

  copyTitle(): void {
    this.$copyText(this.$t("AddFundsInfo_Receiver_Caption") as string);
    this.showNameCopied = true;
    setTimeout(() => (this.showNameCopied = false), 2000);
  }

  copyIBAN(): void {
    this.$copyText(this.$t("AddFundsInfo_IbanReceiver_Caption") as string);
    this.showIbanCopied = true;
    setTimeout(() => (this.showIbanCopied = false), 2000);
  }

  async loadUserIBAN(): Promise<void> {
    this.loading = true;
    await this.$store.dispatch("iban/fetchIBAN");
    this.loading = false;
  }

  async setIBAN(): Promise<void> {
    this.loadingSubmit = true;
    this.error = "";
    try {
      await this.$store.dispatch("iban/setIBAN", this.iban);
      await this.$store.dispatch("iban/fetchIBAN");
    } catch (error) {
      console.error(error);
      this.error = this.$t("AddFundsIban_Input_NotValidError") as string;
    }
    this.loadingSubmit = false;
  }

  get userIBANLast4Numbers(): string {
    return this.$store.state.iban.iban.slice(-4);
  }

  get userIBAN(): string {
    return this.$store.state.iban.iban;
  }

  get userHasIBAN(): boolean {
    return this.$store.getters["iban/userHasIBAN"];
  }
}
